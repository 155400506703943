<template>
    <div class="fundsPage">
        <div class="backBtn" @click="$router.go(-1)">
            <svg-icon iconClass="leftArrow"></svg-icon>
        </div>
        <van-tabs swipeable animated @change="changeTabs" v-model="activeKey">
            <van-tab replace to="vipList" title="会员">
                <router-view v-if="activeKey==0"></router-view>
            </van-tab>
            <van-tab replace to="wallet" title="钱包">
                <router-view v-if="activeKey==1"></router-view>
            </van-tab>
        </van-tabs>
        <router-link tag="div" to="transaDetails" class="buyDetail">交易明细</router-link>
        <van-popup position="bottom" v-model="show" @close="close">
            <div class="rchgBox">
                <div class="title">选择支付方式</div>
                <div v-for="(item, index) in payModes" :key="index">
                    <div class="rechargeList" v-if="item.payModeId != 5 && item.payModeId != 0" @click="toPay(item.payModeId)">
                        <svg-icon :iconClass="handlePayType(item.payModeId, true)" class="payLogo" />
                        <div class="type">{{item.payModeName}}</div>
                        <svg-icon iconClass="rightArrow" />
                    </div>
                    <div v-if="item == 5 && dcInfo.traders && dcInfo.traders.length > 0">
                        <div class="dcList" v-for="(x, y) in dcInfo.traders[0].payInfos" :key="y" @click="dcToPay(x)">
                            <svg-icon :iconClass="handlePayType(x.payMethod, true)" class="payLogo" />
                            <div class="titleBox">
                                <div>官方代充</div>
                                <div class="typeBox">
                                    <div>{{handlePayType(x.payMethod)}}</div>
                                    <svg-icon iconClass="tuijie_icon" class="recommend"></svg-icon>
                                </div>
                            </div>
                            <svg-icon iconClass="rightArrow" />
                        </div>
                    </div>
                </div>
                <div class="rechargeList" @click="toPay(0)">
                    <svg-icon iconClass="gold" class="payLogo" />
                    <div class="type">金币兑换</div>
                    <svg-icon iconClass="rightArrow" />
                </div>
            </div>
        </van-popup>
        <div class="kfBox" @click="server">
            <div class="kefu flex-column">
                <div class="kefu-icon"></div>
                <div class="kefu-words">客服</div>
            </div>
        </div>
      <!--  弹窗充值 -->
      <RechgPopups :is-show-rechg-box="isShowRechgBox" :rechgPopData="rechgPopData" :logType="logType" @close="close" :key="activeKey"/>
    </div>
</template>

<script>
    import { rehgSubmit } from '@/api/mine/wallet.js'
    import { getCustom } from "@/api/index.js";
    import RechgPopups from "./commponents/rechgPopups"
    import { optRecord } from "@/utils/optLog.js";
    export default {
        components: {
          RechgPopups
        },
      provide() {
        return {
          rechgPopShow: this.rechgPopShow,
          rechgPopClose:this.rechgPopClose,
          saveRechgInfo: this.saveRechgInfo,
          cleanRechgInfo: this.cleanRechgInfo,
        };
      },
      data() {
          return {
              isShowRechgBox:false,
              rechgPopData:{},
              activeKey: 0,
              show: false, // 特权卡充值显隐
              payModes: [], // 充值方式
              dcInfo: {}, // 代充信息
              dcChannel: '', // 代充域名
              payAmount: '', // 支付金额
              useId: '', // 特权卡ID
              logType: 2, 
          }
        },
        created() {
            if (this.$route.name == 'vipList') {
                this.activeKey = 0
            } else {
                this.activeKey = 1
            }
            this.$store.watch((state, getters) => {
                this.show = getters.privilRchgInfo.status; // 特权卡充值显隐
                this.payModes = getters.privilRchgInfo.payModes; // 充值方式
                this.dcInfo = getters.privilRchgInfo.dcInfo; // 代充信息
                this.dcChannel = getters.privilRchgInfo.dcChannel; // 代充域名
                this.payAmount = getters.privilRchgInfo.payAmount; // 支付金额
                this.useId = getters.privilRchgInfo.useId;
            })

            // console.log(this.$route)
        },
        methods: {
          saveRechgInfo(data){
              this.rechgPopData= data;
          },
          //清空充值数据
          cleanRechgInfo(){
              this.rechgPopData = {};
          },
          rechgPopShow(type){
            //   console.log(type)
            this.logType = type;
            this.isShowRechgBox = true;
          },
          rechgPopClose(){
            this.isShowRechgBox = false;
          },
            changeTabs(i) {
                if (i == 0) {
                    this.$router.replace('/vipList')
                } else {
                    this.$router.replace('/wallet')
                }
            },
            // 提交订单
            async toPay(payType) {
                let vipGrap = JSON.parse(sessionStorage.getItem('vipGrap'));
                if (vipGrap.type == 'video') {
                  optRecord(3, vipGrap.vInfo, "", undefined, 2);
                } else {
                  optRecord(3, {}, "", (vipGrap.type || 'user'), 2);
                }
                let req = {
                    payAmount: this.payAmount,
                    payMode: payType,
                    productType: 0, // 0-站群 1-棋牌
                    rchgUse: 1, // 0-金币 1-vip 2-观影券 3-vip升级卡 4-瓦力棋牌 5-裸聊果币
                    useId: this.useId,
                }
                this.$store.dispatch("app/GET_LOADING", true);
                let ret = await this.$Api(rehgSubmit, req);
                if (ret && ret.code == 200) {
                    if (payType != 0) {
                        window.location = ret.data.payUrl;
                    } else {
                        this.$toast('购买成功')
                    }
                } else {
                    this.$toast(ret.tip || '获取支付方式失败');
                }
                let info = {
                    dcInfo: {},
                    dcChannel: "",
                    payModes: [],
                    payAmount: "",
                    useId: "",
                    status: false,
                }
                this.$store.dispatch("user/setPrivilRchgInfo", info);
                this.$store.dispatch("app/GET_LOADING", false);
            },
            // 代充支付
            dcToPay(dcPayInfo) {
                let vipGrap = JSON.parse(sessionStorage.getItem('vipGrap'));
                if (vipGrap.type == 'video') {
                  optRecord(3, vipGrap.vInfo, "", undefined, 2);
                } else {
                  optRecord(3, {}, "", (vipGrap.type || 'user'), 2);
                }
                this.$store.dispatch("app/GET_LOADING", true);
                let payMoney = this.payMoney / 100;
                let dcData = JSON.parse(JSON.stringify(this.dcInfo)); // 代充信息
                let payInfoModel = JSON.parse(JSON.stringify(dcPayInfo)); // 代充支付类型
                let token = localStorage.getItem('Authorization');
                let productInfo = {
                    id: this.useId,
                    type: 1,
                }
                //设置默认值
                if (dcData.limit == 0) {
                    dcData.limit = 500;
                }
                if (payInfoModel.payType.includes(2) && payInfoModel.payType.includes(3)) {
                    if (payMoney > dcData.limit) {
                        payInfoModel.payType = [3];
                    } else {
                        payInfoModel.payType = [2];
                    }
                }
                if (payInfoModel.payType.length >= 3) {
                    if (payMoney > dcData.limit) {
                        payInfoModel.payType = [1, 3];
                    } else {
                        payInfoModel.payType = [1, 2];
                    }
                }
                dcData.traders[0].payInfos = [payInfoModel];
                dcData.productInfo = productInfo;
                dcData.chargeMoney = payMoney;
                dcData.channel = this.dcChannel;
                let models = JSON.stringify(dcData);
                let payUrl = `${this.dcChannel}/?data=${this.encodeBase64(models)}&token=${token}`;
                this.$store.dispatch("app/GET_LOADING", false);
                window.location = payUrl;
                let info = {
                    dcInfo: {},
                    dcChannel: "",
                    payModes: [],
                    payAmount: "",
                    useId: "",
                    status: false,
                }
                this.$store.dispatch("user/setPrivilRchgInfo", info);
            },
            // 转base64
            encodeBase64(str) {
                let base64Str = Buffer.from(str, 'utf-8').toString('base64');
                return base64Str;
            },
            // 支付类型
            handlePayType(payType, isSvg) {
                let nameStr, svgStr;
                switch (payType) {
                    case 1:
                        nameStr = '支付宝';
                        svgStr = 'icon_pay_a1';
                        break;
                    case 2:
                        nameStr = '微信';
                        svgStr = 'icon_pay_w1';
                        break;
                    case 3:
                        nameStr = '银行卡';
                        svgStr = 'pay_kjyl';
                        break;
                    case 4:
                        nameStr = 'USDT';
                        svgStr = 'USDT';
                        break;
                    case 6:
                        nameStr = '快捷银联';
                        svgStr = 'pay_kjyl';
                        break;
                    case 7:
                        nameStr = 'QQ支付';
                        svgStr = 'qqPay';
                        break;
                    case 101:
                        nameStr = '支付宝';
                        svgStr = 'icon_pay_a1';
                        break;
                    case 102:
                        nameStr = '微信';
                        svgStr = 'icon_pay_w1';
                        break;
                    case 103:
                        nameStr = '银行卡';
                        svgStr = 'pay_kjyl';
                        break;
                    case 104:
                        nameStr = '信用卡';
                        svgStr = 'pay_kjyl';
                        break;
                    case 105:
                        nameStr = '花呗';
                        svgStr = 'icon_pay_a1';
                        break;
                    case 106:
                        nameStr = '云闪付';
                        svgStr = 'pay_kjyl';
                        break;
                    case 107:
                        nameStr = 'QQ钱包';
                        svgStr = 'pay_kjyl';
                        break;
                    case 108:
                        nameStr = '京东支付';
                        svgStr = 'pay_kjyl';
                        break;
                    default:
                        nameStr = '支付宝';
                        svgStr = 'icon_pay_a1';
                        break;
                }
                return isSvg ? svgStr : nameStr;
            },
            async server() {
                let toast = this.$toast.loading({
                    duration: 0,
                    message: "加载中...",
                    forbidClick: true,
                });
                // this.$store.dispatch("app/GET_LOADING", {isLoading: true, loadingText: '加载中...'});
                let ret = await this.$Api(getCustom);
                // this.$store.dispatch("app/GET_LOADING", {isLoading: false, loadingText: '加载中...'});
                toast.clear();
                if (ret.code == 200) {
                    let endpoint = ret.data.endpoint;
                    location = this.$store.state.app.baseUrl + endpoint;
                    return;
                }
                this.$toast(ret.tip || "加载客服失败");
            },
            close() {
                let info = {
                    dcInfo: {},
                    dcChannel: "",
                    payModes: [],
                    payAmount: "",
                    useId: "",
                    status: false,
                }
                this.$store.dispatch("user/setPrivilRchgInfo", info);
            }
        }
    }
</script>

<style lang='scss' scoped>
    .fundsPage {
        position: relative;

        .backBtn {
            font-size: 18px;
            position: absolute;
            top: 13px;
            left: 10px;
            z-index: 3;
        }

        .buyDetail {
            font-size: 14px;
            position: absolute;
            top: 14px;
            right: 10px;
            z-index: 3;
        }

        /deep/ .van-tabs__nav--line {
            width: 154px;
            height: 30px;
            border-radius: 15px;
            background: $whiteTen;
            margin: 7px auto;
        }

        /deep/ .van-tabs__nav--line {
            padding: 0;
        }

        /deep/ .van-tabs__line {
            display: none;
        }

        /deep/ .van-tab--active {
            color: $white1;
            background: $vermillion;
            border-radius: 15px;
        }

        .rchgBox {
            padding: 10px;
            max-height: 400px;

            .title {
                font-size: 16px;
                margin-bottom: 12px;
            }

            .rechargeList {
                // padding-bottom: 7.5px;
                height: 50px;
                margin: 0 10px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-bottom: 1px solid rgba(139, 139, 139, 0.3);

                .payLogo {
                    width: 34px;
                    height: 34px;
                }

                .type {
                    flex: 2;
                    margin-left: 20px;
                    font-size: 14px;
                }
            }

            .dcList {
                height: 50px;
                margin: 0 10px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-bottom: 1px solid rgba(139, 139, 139, 0.3);

                .payLogo {
                    width: 34px;
                    height: 34px;
                }

                .titleBox {
                    flex: 2;
                    margin-left: 20px;
                    height: 34px;
                    font-size: 14px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    .typeBox {
                        font-size: 10px;
                        display: flex;
                        align-items: center;

                        .recommend {
                            margin-left: 8px;
                            width: 25px;
                            height: 12.5px;
                        }
                    }
                }
            }
        }

        .kfBox {
            position: fixed;
            bottom: 100px;
            right: 20px;
        }

        .kefu {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            background: $vermillion;
            margin-top: 46px;
            align-items: center;
            justify-content: center;
            color: #ffffff;
        }

        .kefu-icon {
            width: 38px;
            height: 38px;
            background: url('../../../assets/png/search_icon.png') center center no-repeat;
            background-size: 100%;
        }

        .kefu-words {
            margin-top: 2px;
        }
    }
</style>

import {
  addLog
} from "@/api/app.js"
import {
  Api
} from "@/utils/index.js";

/**
 * 
 * @param {*} type 
 * @returns 
 */
export var optRecord = (type, videoInfo, tag, graphicsId, graphicsType) => {
  let logApi = JSON.parse(sessionStorage.getItem('logUrl'));
  // console.log(logApi)
  // console.log(!logApi)
  if (!logApi) return;
  let req;
  let videoPlay = sessionStorage.getItem('videoPlay') ? JSON.parse(sessionStorage.getItem('videoPlay')) : {};
  if (type == 1) {
    req = {
      dataType: type,
      videoDatas: {
        appId: 7,
        videoId: videoInfo.id.toString(),
        videoTitle: videoInfo.content,
        userType: isVip() ? 1 : 2,
      }
    }
    // console.log(234234)
    let hasKey = Object.prototype.hasOwnProperty.call(videoPlay, videoInfo.id.toString());
    // console.log(hasKey)
    // if (videoPlay.hasOwnProperty(videoInfo.id.toString())) {
    //     return;
    // }
    if (hasKey) {
        return;
    }
    videoPlay[videoInfo.id] = videoInfo.content;
    sessionStorage.setItem("videoPlay", JSON.stringify(videoPlay))
  } else if (type == 2) {
    // let tags = [];
    // videoInfo.tags.forEach(i => {
    //   tags.push(i.name);
    // });
    // console.log(isVip())
    req = {
      dataType: type,
      tagDatas: {
        appId: 7,
        tagsName: tag,
        userType: isVip() ? 1 : 2,
      }
    }
  } else if (type == 3) {
    // console.log(videoInfo)
    req = {
      dataType: type,
      vipGraphicss: {
        graphicsId: graphicsId || videoInfo.id + "",
        graphicsTitle: graphicsId ? graphicsId : videoInfo.content,
        appId: 7,
        userType: isVip() ? 1 : 2,
        graphicsType: graphicsType,
      }
    }
  }
  // console.log(req)
  return Api(addLog, req);
}


// function isVip() {
//   let isvipExpire =
//     new Date(
//       JSON.parse(sessionStorage.getItem("userInfo")).vipExpire
//     ).getTime() > new Date().getTime() ?
//     true :
//     false;
//   let uVip = JSON.parse(sessionStorage.getItem("userInfo")).isVip || false;
//   if (isvipExpire && uVip) {
//     return true;
//   }
//   return false;
// }

function isVip () {
  let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  return Number(userInfo.vipExpire * 1000) > Number(new Date().getTime());
}
